@import "../../global.scss";

.intro{

    @include mobile{
        flex-direction:column;
        align-items: center;
        overflow: visible;
    }

    display: flex;

    $pp-purple: #393e41;
    $pp-blue: #071924;
    $pp-ltblue: #383d3e;
    $sp-yellow: #2b333e;
    background-image: linear-gradient(to right, $pp-blue,$pp-ltblue,$sp-yellow,$pp-purple,$pp-blue);
    background-size: 600%;
    background-position: 0 0;
    box-shadow: inset 0 0 5em rgba(0,0,0,.5);
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-name: gradients;

    
    @keyframes gradients {
        0%   {background-position: 0 0;}
        25% {background-position: 50% 0;}
        50% {background-position: 90% 0;}
        60% {background-position: 60%;}
        75% {background-position: 40%;}
        100%  {background-position: 0 0;}
    }

    .left {

        @include mobile{
           flex:0.5;
            overflow: visible;
        }

        flex: 0.5;
        &::-webkit-scrollbar{
            display: flex;
            overflow-y: hidden;
        }
        
        .imgContainer{

            @include mobile{
                width: 300px;
                height: 300px;
                display: flex;
                overflow: visible;
                padding-left: 0;
                padding-top: 20px;
            }
            width: 1000px;
            height: 650px;
            display: flex;
            align-items:center;
            justify-content:flex-start;
            scrollbar-width: none;
            scroll-behavior: smooth;
            padding-left: 60px;
            overflow-y: hidden;
            position: sticky;   
                    
            img{
                width: 50%;
                z-index: 0;
                box-shadow: 5px 5px 30px 15px rgba(0, 0, 0, 0.25), 
                -5px -5px 30px 15px rgba(0,0,0,0.22);
                transition: 1s;
                border-width: 3px;

                &:hover{
                    transform: scale(0.9, 0.9);
                    object-fit: cover;
                    border-radius: 50%;
                    
                }

                @include mobile{
                    height: 100%;
                    width: 100%;
                    overflow: visible;
                }

            }

        } 
    }

    .right{

        
        @include mobile{
            flex:0.6;
            padding-left: 60px;
 
         }
        flex: 0.8;
        position: relative;
        &::-webkit-scrollbar{
            display: none;
            overflow-y: hidden;
        }
        .wrapper{
            width: 100%;
            height: 100%;
            padding-left: 40px;
            scroll-padding: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            

            h1{
                font-size: 90px;
                font-weight: lighter;
                color: rgb(255, 255, 255);
                margin: 10px 0;
                font-family: 'Lobster', cursive;
                text-decoration: none;
                letter-spacing: 2px;
            }
            h2{
                font-size: 45px;
                font-weight: lighter;
                color: rgba(255, 255, 255, 0.525);
            }
            h3{
                font-size: 30px;
                color: rgba(255, 255, 255, 0.525);
                font-weight: lighter;

                span{
                    
                    font-size: inherit;
                    color:rgba(255, 255, 255, 0.537);
                    
                }
            }

            &:hover{
                cursor:default;
            }

        }
        
    }

}