@import "../../global.scss";

.works{


    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;    

    .arrow{
        height: 70px;
        position: absolute;
        transform: rotate(270deg);
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        @include mobile{
            height: 30px;
            filter: invert(100%);
        }

        &.left{
           left: 100px;
           transform: rotate(90deg);
           cursor: pointer;

           @include mobile{
            left: 10px;
            top: 20px;
           }

            
            &:hover{
                transform: scale(0.8) rotate(90deg);
            }
        }

        &.right{
            right: 100px;

            @include mobile{
                right: 10px;
                top: 20px;
            }
            
            &:hover{
                transform: scale(0.8) rotate(270deg);
                
            }
        }

    }

    background: url("../../../public/assets/IMG_1985.JPEG") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .slider{
        height: 550px;
        display: flex;
        position: absolute;
        left: 0;
        transition: all 0.5s ease-in-out;

        .container{
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;         

            .item{
                width: 900px;
                height: 100%;
                background-color: aliceblue;
                border-radius: 20px;
                display:flex;
                align-items: center;


                .left{
                    flex: 4;
                    height: 80%;
                    margin-left: 50px;
                    display: flex;
                    padding-top: 50px;
                    align-items: center;
                    justify-items: center;

                    @include mobile{
                        height: 90%;
                        
                    }

                    .leftContainer{
                        width: 90%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        white-space: pre-line;

                        .imgContainer{
                           width: 40px;
                           height: 40px;
                           border-radius: 50%;
                           background-color: #fff; 
                           display: flex;
                           align-items: center;
                           justify-content: center;

                           img{
                            width: 25px;
                           }
                        }

                        h2{
                            font-size: 20px;
                            color: #fff;
                            scrollbar-width: none;
                            overflow: hidden;

                            @include mobile{
                                font-size: 15px;
                                padding-bottom: 5px;
                                
                            }
                           }
                           p{
                            font-size: 13px;
                            color: #fff;
                            @include mobile{
                                font-size: 11px;
                                
                            }
                           }
                           span{
                            font-size: 12px;
                            font-weight: 600;
                            color: #fff;
                            cursor: pointer;
                            text-decoration: underline
                           }

                    }

                }

                .right{
                    flex: 5;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover{
                        
                        transition: all 0.5 ease-in-out;
                    }

                    iframe{
                        margin-right: 50px;
                        border-radius: 30px;
                        height: 450px;
                        width: 550px;
                        border:none;

                        @include mobile{
                            height: 300px;
                            width: 400px;
                        }
                    }

                }

                $pp-purple: #3c4245;
                $pp-blue: #3c4142;
                $pp-ltblue: #292f42;
                $sp-yellow: #232529;
                background-image: linear-gradient(to right, $pp-blue,$pp-ltblue,$sp-yellow,$pp-purple,$pp-blue);
                background-size: 600%;
                background-position: 0 0;
                box-shadow: inset 0 0 5em rgba(0,0,0,.5);
                animation-duration: 20s;
                animation-iteration-count: infinite;
                animation-name: gradients;

    
                @keyframes gradients {
                    0%   {background-position: 0 0;}
                    25% {background-position: 50% 0;}
                    50% {background-position: 90% 0;}
                    60% {background-position: 60%;}
                    75% {background-position: 40%;}
                    100%  {background-position: 0 0;}
                }
            }
        }
    }

}