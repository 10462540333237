@import "../../global.scss";

.topbar{
    width:100%;
    height: 70px;
    background-color: rgba(255, 255, 255, 0.187);
    color: $mainColor;
    position: fixed;
    top: 0;
    z-index: 3;
    transition: all 0.5s ease;
    scrollbar-width: none;
        &::-webkit-scrollbar{
            display: none;
        }

    .wrapper{
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        scrollbar-width: none;
        &::-webkit-scrollbar{
            display: none;
        }

        .left{
            .logo{
                font-family: 'Londrina Shadow', cursive;
                align-items: center;
                font-weight: bold;
                color: inherit;
                font-size: 40px;
                text-decoration: none;
                letter-spacing: 7px;
                scrollbar-width: none;
                &::-webkit-scrollbar{
                    display: none;
                }

                &:hover{
                    background-color: beige;
                    background-position: 8ex;
                    transition: background-color 1s;
                }
                
            }

        }
        .right{
            .hamburger{
                width: 32px;
                height: 25px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;
                scrollbar-width: none;
                &::-webkit-scrollbar{
                    display: none;
                }

                span{
                    width: 100%;
                    height: 4px;
                    border-radius: 4px;
                    background-color: $mainColor;
                    transform-origin: left;
                    transition: all 0.5s ease-in-out;
                    
                }
            }
        }
    }
    &.active{
        background-color: $mainColor;
        color: beige;

        .hamburger{
            span{
                &:first-child{
                    background-color: beige;
                    transform: rotate(45deg);
                    transition: all 0.5s ease-in-out;
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:last-child{
                    background-color: beige;
                    transform: rotate(-45deg);
                    transition: all 0.5s ease-in-out;
                }
            }
        }
    }
}