@import "../../global.scss";
.menu{
    
    width: 100%;
    height: 100vh;
    background-color: $mainColor ;
    display: flex;
    background-image: url("../../../public/assets/MenuGraphics.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-blend-mode: color-dodge;
    background-position-y: bottom ;
    position: fixed;
    top: 0;
    right: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    scrollbar-width: none;
    transition: all 0.5s ease-in-out;


    &::-webkit-scrollbar{
        display: none;
    }
    
    &.active{
        right: 0;
    }

    ul{
        margin:0;
        padding: 0;
        list-style: none;
        font-size: 40px;
        color: beige;
        width: 80%;
        scrollbar-width: none;

        &::-webkit-scrollbar{
            display: none;
        }


        li{
            margin-bottom: 25px;
            text-align:right;
            width: 100%;

            a{
                font-size: inherit;
                color: inherit;
                text-decoration: none;
                transition: all 0.5s ease-in-out;
            }

            &:hover {
                scrollbar-width: none;
                color: rgb(55, 150, 172);
                width: 100%;
            }
            
        }
    }
}