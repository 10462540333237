$mainColor: #071924;

.bcg{
    $pp-purple: #4b0f2f;
    $pp-blue: #054f7d;
    $pp-ltblue: #96c9d6;
    $sp-yellow: #6d6c5e;
    background-image: linear-gradient(to right, $pp-blue,$pp-ltblue,$sp-yellow,$pp-purple,$pp-blue);
    background-size: 600%;
    background-position: 0 0;
    box-shadow: inset 0 0 5em rgba(0,0,0,.5);
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-name: gradients;

    
    @keyframes gradients {
        0%   {background-position: 0 0;}
        25% {background-position: 50% 0;}
        50% {background-position: 90% 0;}
        60% {background-position: 60%;}
        75% {background-position: 40%;}
        100%  {background-position: 0 0;}
    }
}

$width : 768px;

@mixin mobile {
    @media (max-width: #{$width}) {
        @content
    }
}

@mixin tablet {
    @media (max-width: 1000px){
        @content
    }
}