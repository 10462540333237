@import"../../global.scss";
.portfolio{
    // background-image: linear-gradient(#252a2d,#32465a);
    background-color: #252a2d;
    background-image: url("../../../public/assets/Layover.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-x:center absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    @include mobile{
          background-image: linear-gradient(#252a2d,#32465a);
          
    }

    .portfolio span {
      text-transform: uppercase;
      display: block;
      
    }
    
    .text1 {
      color: white;
      padding-top: 35px;
      font-size: 60px;
      font-weight: 200;
      letter-spacing: 4px;
      padding-bottom: 24px;
      position: relative;
      overflow: hidden; 

      @include mobile{
        padding-top: 35px;
        font-size: 35px;
        letter-spacing: 4px;
        padding-bottom: 1px;
      }
      
    }
        
    .container{
      padding-top: 50px;
      overflow: hidden;
      
      @include mobile{
        padding-top:5px; 
      }
        .cards-list {
            z-index: 0;
            width: 100%;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
          }
          
          .card {
            margin: 30px auto;
            width: 300px;
            height: 300px;
            border-radius: 40px;
            padding: 20px 20px 20px 20px;

            cursor: pointer;
            transition: 0.5s;

            @include mobile{
              margin: 10px auto;
              width: 210px;
              height: 210px;
              border-radius: 40px;
              padding: 20px 20px 20px 20px;
            }
          }
          
          .card .card_image {
            width: inherit;
            height: inherit;
            border-radius: 40px;
            overflow: hidden;
              scrollbar-width: none;
                &::-webkit-scrollbar{
                display: none;
              }
          }
          
          .card .card_image img {
            width: 300px;
            height: 300px;
            border-radius: 40px;
            object-fit: cover;
        

            @include mobile{
              width: 210px;
              height: 210px;
              border-radius: 20px;
              object-fit: cover;
            }
          }
          
          .card:hover {
            display: block;
            transform: scale(0.9, 0.9);
            box-shadow: 2px 2px 5px 5px rgba(0,0,0,0.25), 
              -2px -2px 5px 5px rgba(0, 0, 0, 0.22);
          }
          
          .title-white {
            color: white;
          }
          
          .title-black {
            color: black;
          }
          
          @media all and (max-width: 500px) {
            .card-list {
              flex-direction: column;
            }
          }
    }
}