
@import "../../global.scss";

.contact {

  background-color: white;
  display: flex;

  @include mobile {
    flex-direction: column;
    
  }

  .left {
    flex: 1.2;
    overflow: hidden;

    @include mobile{
      flex: 0;
      overflow: visible;
    }
    ;
    

    img {
      height: 100%;

      @include mobile{
        filter: brightness(0.9);
        position: absolute;
        
      }
    }


  }
  .right {
    flex: 0.7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow-y: hidden;
    @include mobile{
      position: sticky;
      padding-top: 100px;
      overflow-y: hidden;
      padding-bottom: 2px;
    }

    h2 {
      font-size: 30px;
    }

    .contact-form {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      @include mobile{
        
      }
      

      input {

        width: 300px;
        height: 30px;
        font-size: 14px;
        overflow-y: hidden;
        

        @include mobile {
          width: 300px;
          height: 20px;
        }
        
      }

      label{
        font-size: 15px;
        overflow-y: hidden;
        border-color: rgb(255, 255, 255);
        border-width: 5px;
        border-style: double;
        background-color: white;
        border-radius: 5px;
        margin-bottom: 10px;
      }

      textarea {
        width: 300px;
        height: 200px;
        font-size: 14px;

        @include mobile {
          width: 300px;
          height: 100px;
        }
      }

      span {
        color: green;
        overflow-y: hidden;
      }
    }
  }
}